"use strict";

export default {
    data() {
        return {
            "sortTypeToggle": true,
            "sortTypeToggleKey": "",
        };
    },
    "methods": {
        $_sort(event, sort) {
            const thCount = event.target.parentNode.childElementCount;
            for(let i = 0; i < thCount; i++) {
                event.target.parentNode.childNodes[i].classList.remove("is-asc");
                event.target.parentNode.childNodes[i].classList.remove("is-desc");
            }
            let compare = null;
            let mode = "";
            if (!sort.type) {
                sort.type = "asc";
            }
            if (!this.sortTypeToggleKey && sort.type != "asc") {
                this.sortTypeToggle = false;
            }
            if (sort.number) {
                for (let i in sort.number) {
                    if (sort.key === sort.number[i]) {
                        mode = "number";
                    }
                }
            }
            if (this.sortTypeToggleKey === sort.key) {
                this.sortTypeToggle = !this.sortTypeToggle;
            } else {
                this.sortTypeToggle = false;
            }
            if (this.sortTypeToggle) {
                sort.type = "asc";
            } else {
                sort.type = "desc";
            }
            event.target.classList.add(`is-${sort.type}`);
            if (mode === "number") {
                if (sort.type === "desc") {
                    compare = (a, b) => b[sort.key] - a[sort.key];
                } else {
                    compare = (a, b) => a[sort.key] - b[sort.key];
                }
            } else {
                compare = (a, b) => {
                    if (a[sort.key] < b[sort.key]) {
                        if (sort.type === "desc") {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else if (a[sort.key] > b[sort.key]) {
                        if (sort.type === "desc") {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                    return 0;
                };
            }
            this.sortTypeToggleKey = sort.key;
            return sort.data.sort(compare);
        },
    },
};

