"use strict";

import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import ja from "vee-validate/dist/locale/ja.json";
for (let rule in rules) {
    extend(rule, {
        ...rules[rule],
        "message": ja.messages[rule],
    });
}

// パスワード用カスタムルール設定
const passWord = {
    "message": "{_field_}は英数字混合の8文字以上15文字以下で入力してください。",
    validate(value) {
        const rules = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,15}$/i;
        return rules.test(value);
    },
};
extend("password", passWord);

export default {
    "components": {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            "classButton": "button--oval--",
            "flagValidatedImmediate": false,
            "X_classValidatedButton": "button--oval--invalid",
        };
    },
    "methods": {
        $_setValidatedDisabled(invalid) {
            // バリデーションによるボタンの有効化・無効化
            if (invalid) {
                this.X_classValidatedButton = `${this.classButton}invalid`;
                return true;
            } else {
                this.X_classValidatedButton = `${this.classButton}primary`;
                return false;
            }
        },
        $_setValidatedImmediate() {
            // 即時バリデーションチェックを有効にする
            this.flagValidatedImmediate = true;
        },
        $_setValidatedImmediateRemove() {
            // 即時バリデーションチェックを無効にする
            this.flagValidatedImmediate = false;
        },
        $_getValidatedImmediate() {
            // 即時バリデーションチェックの有無を取得
            return this.flagValidatedImmediate;
        },
    },
};
