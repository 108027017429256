<template>
    <div>
        <transition name="modal">
            <div v-if="modal.status.show" class="modal" @click.self="close()">
                <div class="modal__dialog" @click.self="close()">
                    <transition name="modal">
                        <div class="modal__content">
                            <div class="modal__content__header">
                                <template v-if="!loader || modal.status.error">
                                    <button class="modal-close" type="button" @click.self="close()"></button>
                                </template>
                            </div>
                            <div class="modal__content__body">
                                <template v-if="modal.status.error">
                                    {{ modal.data.message.error }}
                                </template>
                                <template v-else-if="modal.status.finished">
                                    {{ modal.data.message.end }}
                                </template>
                                <template v-else-if="!loader">
                                    {{ modal.data.message.start }}
                                </template>
                                <template v-else>
                                    <Loader :loader="loader" />
                                </template>
                            </div>
                            <div class="modal__content__footer">
                                <template v-if="modal.status.error">
                                    <button :class="`${classButton}cancel`" type="button" @click.self="close()">{{ modal.data.button.error }}</button>
                                </template>
                                <template v-else-if="modal.status.finished">
                                    <button :class="`${classButton}primary`" type="button" @click="action()">{{ modal.data.button.finished }}</button>
                                </template>
                                <template v-else-if="!loader">
                                    <button v-if="modal.data.button.cancel" :class="`${classButton}cancel`" type="button" @click.self="close()">{{ modal.data.button.cancel }}</button>
                                    <button v-if="modal.data.button.primary" :class="`${classButton}primary`" type="button" @click="action()">{{ modal.data.button.primary }}</button>
                                    <button v-else-if="modal.data.button.other" :class="`${classButton}other`" type="button" @click="action()">{{ modal.data.button.other }}</button>
                                    <button v-else-if="modal.data.button.delete" :class="`${classButton}delete`" type="button" @click="action()">{{ modal.data.button.delete }}</button>
                                </template>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
        <transition name="modal-backdrop">
            <div v-if="modal.status.show" class="modal-backdrop"></div>
        </transition>
    </div>
</template>

<script>
    "use strict";

    import Loader from "@/components/Loader.vue";

    export default {
        "components": {
            Loader,
        },
        "props": {
            "modal": {
                "required": true,
                "type": Object,
            },
        },
        data() {
            return {
                "classButton": "button--oval--",
                "loader": false,
            };
        },
        "methods": {
            action() {
                let actionKey;
                this.loader = true;
                if (this.modal.status.finished) {
                    actionKey = `${this.modal.data.key}--finished`;
                } else {
                    actionKey = this.modal.data.key;
                }
                if (this.modal.status.finished || this.modal.status.error) {
                    this.loader = false;
                }
                this.$emit("action", actionKey);
            },
            close() {
                if (!this.loader || this.modal.status.error) {
                    this.loader = false;
                    this.$emit("close");
                }
            },
        },
    };
</script>
