"use strict";

const url = "https://aoifoods.sjc-dev-lx.com";

const configs = {
    "url": url,
    "apiUrl": `${url}/api/api`,
    "filesUrl": `${url}/admin/api/public/storage/`,
};

export default configs;
