<template>
    <div v-if="loader">
        <img class="loader" src="@/assets/images/loading-icon.png" alt="">
    </div>
</template>

<script>
    "use strict";

    export default {
        "props": {
            "loader": {
                "default": false,
                "required": true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .loader {
        height: 32px;
        max-width: 32px;
        animation: loader 2s linear infinite;
        margin: 0 !important;
    }

    @keyframes loader {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(-359deg);
        }
    }
</style>
